
import { defineComponent } from 'vue'
import ThemeSwitcher from '@/components/ThemeSwitcher.vue'
import { theme } from '@/store'
import { useToggle } from '@vueuse/shared'

export default defineComponent({
  name: 'App',
  components: {
    ThemeSwitcher
  },
  setup () {
    return {
      theme: theme,
      toggleDark: useToggle(theme)
    }
  }
})
